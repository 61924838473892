





























import {Component, Mixins, Watch} from 'vue-property-decorator';
import {vxm} from '@/store';
import Feed from '@/components/Feed.vue';
import CreatePostButton from '@/components/CreatePostButton.vue';
import Loader from '@/components/Loader.vue';
import NoContent from '@/components/NoContent.vue';
import LoaderBottom from '@/components/LoaderBottom.vue';
import FeedComplete from '@/components/FeedComplete.vue';
import {FeedSubscriptions} from '@/constants/feedSubscriptions';
import FeedMixin from '@/mixins/FeedMixin';
import PostInterface from '@/types/PostInterface';
import OlderPosts from '@/components/modals/OlderPosts.vue';

@Component({
  name: 'Home',
  components: {OlderPosts, NoContent, Loader, Feed, CreatePostButton, LoaderBottom, FeedComplete},
})
export default class Home extends Mixins(FeedMixin) {
  friendsFeed = [] as PostInterface[];
  friendsTotal = 0;
  friendsComplete = false;
  friendsPage = 1;
  generalFeed = [] as PostInterface[];
  generalTotal = 0;
  generalComplete = false;
  showAlreadySeenPosts = false;
  generalPage = 1;
  totalFetchLimit = 100;
  olderPostsShown = false;
  olderPosts = [] as PostInterface[];
  olderPostsParams = {
    page: 1,
    limit: 10,
    isNotViewed: false,
    subscribe: 'friends',
    onlyMedia: this.isGridView,
  };
  olderPostsTotal = 0;
  olderPostsComplete = false;
  loading = false;
  requestSend = false;

  get noContentMessage(): string {
    return 'Nothing found to show...\nTry coming later!';
  }
  get totalPosts(): number {
    return this.feed.length + this.friendsFeed.length + this.generalFeed.length;
  }
  get allComplete(): boolean {
    return this.complete && this.friendsComplete && this.generalComplete;
  }
  get fetchLimitReached(): boolean {
    return this.totalPosts >= this.totalFetchLimit;
  }
  get feedSubscriptionType(): string {
    if (!this.complete) {
      return FeedSubscriptions.Self;
    }
    if (!this.friendsComplete) {
      return FeedSubscriptions.Friends;
    }
    return FeedSubscriptions.General;
  }
  get isGridView(): boolean {
    return vxm.user.viewAsGrid;
  }
  @Watch('isGridView') onViewChanged() {
    this.page = 1;
    this.complete = false;
    this.friendsFeed = [] as PostInterface[];
    this.friendsTotal = 0;
    this.friendsComplete = false;
    this.friendsPage = 1;
    this.generalFeed = [] as PostInterface[];
    this.generalTotal = 0;
    this.generalComplete = false;
    this.generalPage = 1;
    this.olderPosts = [] as PostInterface[];
    this.olderPostsParams.page = 1;
    this.olderPostsParams.onlyMedia = this.isGridView;
    this.olderPostsTotal = 0;
    this.olderPostsComplete = false;
    this.loading = false;
    this.getFeed();
    if (this.olderPostsShown) {
      this.getOlderPosts();
    }
  }

  @Watch('complete')
  onFeedCompleted() {
    if (this.complete) {
      setTimeout(() => {
        this.onScroll();
      }, 300);
    }
  }
  @Watch('friendsComplete')
  onFriendsComplete() {
    if (this.friendsComplete) {
      setTimeout(() => {
        this.onScroll();
      }, 300);
    }
  }
  @Watch('fetchLimitReached')
  onFetchLimitReached() {
    this.complete = true;
    this.friendsComplete = true;
    this.generalComplete = true;
  }

  getFeed(): void {
    if (this.fetchLimitReached || this.requestSend) {
      return;
    }
    this.requestSend = true;
    vxm.feed
      .getFeed(this.getParams())
      .then(async (res: {data: {items: PostInterface[]; total: number}}) => {
        if (!this.complete) {
          this.feed.push(...res.data.items);
          this.total = res.data.total;
          if (!this.fetchLimitReached) {
            await this.onFeedGrow();
          }
        } else if (!this.friendsComplete) {
          await this.onFriendsFetched(res);
        } else if (!this.generalComplete) {
          await this.onGeneralFetched(res);
        }
        this.requestSend = false;
        if (!res.data.items.length) {
          this.getFeed();
        }
      })
      .catch(() => {
        if (!this.complete) {
          this.complete = true;
        } else if (!this.friendsComplete) {
          this.friendsComplete = true;
        } else if (!this.generalComplete) {
          this.generalComplete = true;
        }
        this.requestSend = false;
      });
  }
  getOlderPosts() {
    vxm.feed.getFeed(this.olderPostsParams).then(async (res: {data: {items: PostInterface[]; total: number}}) => {
      this.olderPosts.push(...res.data.items);
      this.olderPostsTotal = res.data.total;
      this.olderPostsComplete = this.olderPostsTotal === this.olderPosts.length;
    });
  }
  showOlderPosts() {
    this.olderPostsParams.page = 1;
    this.olderPosts = [];
    this.olderPostsComplete = false;
    this.olderPostsShown = true;
    this.getOlderPosts();
  }
  async onFriendsFetched(res: {data: {items: PostInterface[]; total: number}}) {
    this.friendsFeed.push(...res.data.items);
    this.friendsTotal = res.data.total;
    if (this.fetchLimitReached) {
      return;
    }
    if (this.limit * this.friendsPage >= this.friendsTotal) {
      this.friendsComplete = true;
    }
    this.friendsPage += 1;
    await this.$nextTick();
    if (this.isPageBottom() && !this.friendsComplete) {
      this.debounceHandler();
    }
  }
  async onGeneralFetched(res: {data: {items: PostInterface[]; total: number}}) {
    this.generalFeed.push(...res.data.items);
    this.generalTotal = res.data.total;
    if (this.fetchLimitReached) {
      return;
    }
    if (this.limit * this.generalPage >= this.generalTotal) {
      this.showAlreadySeenPosts = true;
    }
    if (res.data.items.length) {
      this.generalPage += 1;
    }
    await this.$nextTick();
    if (this.isPageBottom() && !this.generalComplete) {
      this.debounceHandler();
    }
  }
  getParams() {
    return {
      page: this.getPage(),
      limit: this.limit,
      onlyMedia: this.isGridView,
      subscribe: this.feedSubscriptionType,
      ...(this.feedSubscriptionType === FeedSubscriptions.General && this.showAlreadySeenPosts && {isNotViewed: false}),
    };
  }
  getPage(): number {
    if (!this.complete) {
      return this.page;
    }
    if (!this.friendsComplete) {
      return this.friendsPage;
    }
    return this.generalPage;
  }
  onScroll(): void {
    if (this.olderPostsShown) {
      if (this.isPageBottom()) {
        this.olderPostsParams.page += 1;
        this.getOlderPosts();
      }
      return;
    }
    if (this.allComplete) {
      return;
    }
    if (this.isPageBottom()) {
      this.getFeed();
    }
  }
}
