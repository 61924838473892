








import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';

@Component
export default class FeedComplete extends Vue {
  @Prop({type: String, default: `That's all for now...`}) readonly feedCompleteTitle!: string;
  @Prop({type: String, default: 'continue scrolling to see more...'}) readonly feedCompleteSubtitle!: string;
  @Prop({type: Boolean, default: false}) readonly isMarginBottom!: boolean;
  @Prop({type: Boolean, default: false}) readonly showOlderPostBtn!: boolean;
}
