




import {Component, Vue} from 'vue-property-decorator';

@Component({
  name: 'OlderPosts',
})
export default class OlderPosts extends Vue {}
