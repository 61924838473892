










import {Component, Mixins, Watch} from 'vue-property-decorator';
import {vxm} from '@/store';
import Feed from '@/components/Feed.vue';
import CreatePostButton from '@/components/CreatePostButton.vue';
import Loader from '@/components/Loader.vue';
import NoContent from '@/components/NoContent.vue';
import LoaderBottom from '@/components/LoaderBottom.vue';
import FeedComplete from '@/components/FeedComplete.vue';
import FeedMixin from '@/mixins/FeedMixin';
import PostInterface from '@/types/PostInterface';

@Component({
  name: 'Explore',
  components: {NoContent, Loader, Feed, CreatePostButton, LoaderBottom, FeedComplete},
})
export default class Explore extends Mixins(FeedMixin) {
  loading = false;

  get noContentMessage(): string {
    return 'Nothing found to show...\nTry coming later!';
  }

  get isGridView(): boolean {
    return vxm.user.viewAsGrid;
  }
  @Watch('isGridView') onViewChanged() {
    this.feed = [];
    this.getFeed();
  }

  getFeed(): void {
    this.loading = true;
    vxm.feed
      .getRandomFeed({limit: 10, onlyMedia: this.isGridView})
      .then(async (res: {data: {data: PostInterface[]}}) => {
        if (res.data.data.length) {
          this.feed.push(...res.data.data);
        } else {
          this.complete = true;
        }
        this.loading = false;
      });
  }

  onScroll(): void {
    if (this.complete) {
      return;
    }
    if (this.isPageBottom()) {
      this.getFeed();
    }
  }
}
